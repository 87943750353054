<template>
  <div
    class="body1"
    style="
      background-color: #e1e1e1;
      margin: 0 !important;
      padding: 0 !important;
    "
  >
    <div
      style="
        display: none;
        font-size: 1px;
        color: #fefefe;
        line-height: 1px;
        font-family: 'Lato', Helvetica, Arial, sans-serif;
        max-height: 0px;
        max-width: 0px;
        opacity: 0;
        overflow: hidden;
      "
    >
      We're thrilled to have you here! Get ready to dive into your new account.
    </div>
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
      <!-- LOGO -->
      <tr>
        <td bgcolor="#1746e0" align="center">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="max-width: 600px"
          >
            <tr>
              <td
                align="center"
                valign="top"
                style="padding: 40px 10px 40px 10px"
              ></td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td bgcolor="#1746e0" align="center" style="padding: 0px 10px 0px 10px">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="max-width: 600px"
          >
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                valign="top"
                style="
                  padding: 40px 20px 20px 20px;
                  border-radius: 4px 4px 0px 0px;
                  color: #111111;
                  font-family: 'Lato', Helvetica, Arial, sans-serif;
                  font-size: 48px;
                  font-weight: 400;
                  letter-spacing: 4px;
                  line-height: 48px;
                "
              >
                <h1 style="font-size: 48px; font-weight: 400; margin: 2">
                  Welcome!
                </h1>
                <img
                  src=" https://img.icons8.com/clouds/100/000000/handshake.png"
                  width="125"
                  height="120"
                  style="display: block; border: 0px"
                />
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td bgcolor="#e1e1e1" align="center" style="padding: 0px 10px 0px 10px">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="max-width: 600px"
          >
            <tr>
              <td
                bgcolor="#ffffff"
                align="left"
                style="
                  padding: 20px 30px 40px 30px;
                  color: #666666;
                  font-family: 'Lato', Helvetica, Arial, sans-serif;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 25px;
                "
              >
                <p style="margin: 0">
                  We're excited to have you get started. First, you need to
                  confirm your account. Just press the button below.
                </p>
              </td>
            </tr>
            <tr>
              <td bgcolor="#ffffff" align="left">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td
                      bgcolor="#ffffff"
                      align="center"
                      style="padding: 20px 30px 60px 30px"
                    >
                      <table border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td
                            align="center"
                            style="border-radius: 3px"
                            bgcolor="#1746e0"
                          >
                            <a
                              href="#"
                              target="_self"
                              style="
                                font-size: 20px;
                                font-family: Helvetica, Arial, sans-serif;
                                color: #ffffff;
                                text-decoration: none;
                                color: #ffffff;
                                text-decoration: none;
                                padding: 15px 25px;
                                border-radius: 2px;
                                border: 1px solid #1746e0;
                                display: inline-block;
                              "
                              v-on:click="getRoute()"
                            >
                              Confirm Account</a
                            >
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- COPY -->
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { loadingService } from "../../main/common/services/loading.service";
import { mapState } from "vuex";
import {
  USER_VERIFCATION,
  GET_USER_API_ID,
  CREATE_LIST_API,
} from "../../store/constant/actions.type";
// import { createLog } from "../../main/common/log_rocket/log-rocket-services";
import {
  REDIRECT_CODE,
  REDIRECT_INTEGRATION_NAME,
} from "../../store/constant/mutations.type";
export default {
  name: "email-verified",
  data() {
    return {
      fullPage: false,
      loader: "bars",
    };
  },
  methods: {
    getRoute() {
      loadingService.setloader(true);
      if (
        this.$router &&
        this.$router.history &&
        this.$router.history.current &&
        this.$router.history.current.params &&
        this.$router.history.current.params["email"]
      ) {
        this.$store
          .dispatch(
            USER_VERIFCATION,
            this.$router.history.current.params["email"]
          )
          .then((response) => {
            if (response) {
              if (
                localStorage.getItem(REDIRECT_CODE) &&
                localStorage.getItem(REDIRECT_INTEGRATION_NAME) !== undefined
              ) {
                this.$router.push({ name: "userIntegration" });
              } else {
                // createLog(this.$router.history.current.params["email"]);
                // this.$router.push({ name: "userApi" });
                // this.$router.push({ name: "easy-search" });
                this.$router.push({ name: "get-started" });
              }
              this.getUserApiId(this.$router.history.current.params["email"]);
              loadingService.setloader(false);
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err) {
              console.log("error", err);
              this.flashMessage.error({
                title: "Error Message",
                message:
                  err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                    ? err.response.data.message
                    : err.message,
              });
            }
          });
      }
    },
    getUserApiId(email) {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_API_ID)
        .then((data) => {
          if (data) {
            localStorage.setItem("$s", data.apiId);
            loadingService.setloader(false);
            this.createDefultList(data.apiId, email);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          throw err;
        });
    },

    createDefultList(apiId, email) {
      let payload = {
        apiId: apiId,
        user: email,
        listName: "Default",
      };
      loadingService.setloader(true);
      this.$store
        .dispatch(CREATE_LIST_API, payload)
        .then((response) => {
          if (response) {
            // console.log("datadata => ", response);
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          loadingService.setloader(false);
          throw err;
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
@media screen {
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"),
      url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff)
        format("woff");
  }

  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Bold"), local("Lato-Bold"),
      url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff)
        format("woff");
  }

  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: local("Lato Italic"), local("Lato-Italic"),
      url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff)
        format("woff");
  }

  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
      url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff)
        format("woff");
  }
}

/* CLIENT-SPECIFIC STYLES */
body1,
table,
td,
a {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* table,
td {
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
} */

img {
  -ms-interpolation-mode: bicubic;
}

/* RESET STYLES */
img {
  border: 0;
  height: auto;
  line-height: 100%;
  outline: none;
  text-decoration: none;
}

table {
  border-collapse: collapse !important;
}

body1 {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

/* iOS BLUE LINKS */
a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

/* MOBILE STYLES */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 32px !important;
    line-height: 32px !important;
  }
}

/* ANDROID CENTER FIX */
div[style*="margin: 16px 0;"] {
  margin: 0 !important;
}
</style>
